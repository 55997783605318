// const BASE_URL = 'http://localhost:9001/api/';
// const BASE_URL = 'https://dev.vigorant.net/api/';
const BASE_URL = "https://practice.vigorant.com/api/";

const GET_USERS = BASE_URL + "user/users";
const GET_WORKING_DAYS = BASE_URL + "user/working_days";
const UPDATE_USERS = BASE_URL + "user/";
const GET_ONLY_USERS = BASE_URL + "user/";
const DELETE_USERS = BASE_URL + "user/";
const POST_USERS = BASE_URL + "user/";
const POST_COLLECTION = BASE_URL + "collection/";
const GET_COLLECTION = BASE_URL + "collection/";
const POST_DAILY_REPORT = BASE_URL + "report";
const GET_DAILY_REPORT = BASE_URL + "report?expand=user";
const GET_TYPES = BASE_URL + "type/";
const LOGIN = BASE_URL + "clinic/login/";
const PROVIDER_REPORT = BASE_URL + "report/reporting?is_executive=";
const OFFICE_REPORT = BASE_URL + "collection/getReport";
const CLINIC_DATA = BASE_URL + "report/clinic_details/";

export {
  GET_USERS,
  GET_ONLY_USERS,
  POST_COLLECTION,
  POST_DAILY_REPORT,
  GET_COLLECTION,
  GET_DAILY_REPORT,
  DELETE_USERS,
  LOGIN,
  POST_USERS,
  GET_TYPES,
  UPDATE_USERS,
  PROVIDER_REPORT,
  OFFICE_REPORT,
  CLINIC_DATA,
  GET_WORKING_DAYS,
};
