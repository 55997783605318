import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./pages/auth/Login";
import Forms from "./pages/dashboard/Forms";
import Forgot from "./pages/auth/Forgot";
import Register from "./pages/auth/Register";
import ProtectedRoute from "./utilities/ProtectedRoute";
import Employee from "./pages/dashboard/employee/employee";
import NotFoundPage from "./components/404";
import Report from "./pages/dashboard/Report";
import ExecutiveReport from "./pages/dashboard/ExecutiveReport";
import NewExecutiveReport from "./pages/dashboard/NewExecutiveReport";

const App = () => {
  return (
    <Router>
      <Switch>
        <ProtectedRoute exact path="/" component={Forms} />
        <ProtectedRoute exact path="/team" component={Employee} />
        <ProtectedRoute exact path="/report" component={Report} />
        <ProtectedRoute exact path="/ereport" component={ExecutiveReport} />
        <ProtectedRoute
          exact
          path="/newereport"
          component={NewExecutiveReport}
        />
        <Route exact path="/auth" component={Login} />
        <Route exact path="/clinic/clinic-register" component={Register} />
        <Route exact path="/forgot-password" component={Forgot} />
        <Route exact path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default App;
