import React from "react";

function Divider() {
  return (
    <div
      style={{
        marginTop: "1rem",
        marginBottom: "10px",
        borderTop: "1.5px solid #7367f0",
      }}
    />
  );
}

export default Divider;
