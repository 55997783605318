import { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import { User, Briefcase, Calendar, X } from "react-feather";
import {
  Modal,
  Input,
  Label,
  Button,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import axios from "axios";
import { POST_USERS, GET_TYPES } from "../../../utilities/Endpoints";
import swal from "sweetalert";
import moment from "moment-timezone";
import { ClipLoader } from "react-spinners";
moment.tz.setDefault("America/Los_Angeles");

const AddNewModal = ({ open, handleModal, getUsers }) => {
  const [picker, setPicker] = useState(new Date());
  const [types, setTypes] = useState([]);
  const [typeID, setTypeID] = useState(null);
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);

  const CloseBtn = (
    <X className="cursor-pointer" size={15} onClick={handleModal} />
  );

  const changeType = (e) => {
    setTypeID(e.target.value);
  };

  const handleChange = (evt) => {
    const value = evt.target.value;

    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const handleSubmit = () => {
    const data = {
      joining_date: moment(picker.toString()).format("YYYY-MM-DD"),
      type_id: parseInt(typeID),
      name: state.name,
      goal: state.goal,
      order: state.order,
    };

    setLoading(true);

    const token = localStorage.getItem("token");
    axios
      .post(POST_USERS, data, {
        headers: { user: token },
      })
      .then((response) => {
        swal({
          title: "Success",
          text: "User Added",
          icon: "success",
          button: "OK",
        });
        getUsers(true);
        getData();
        setLoading(false);
        handleModal();
      })
      .catch((err) => {
        setLoading(false);
        handleModal();
        swal("Oops!", "Something went wrong!", "error");
      });
  };

  const getData = () => {
    const token = localStorage.getItem("token");
    axios
      .get(GET_TYPES, {
        headers: { user: token },
      })
      .then((response) => {
        setTypeID(response.data[0].id);
        setTypes(response.data);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Modal
      isOpen={open}
      toggle={handleModal}
      className="sidebar-sm"
      modalClassName="modal-slide-in"
      contentClassName="pt-0"
    >
      <ModalHeader
        className="mb-1"
        toggle={handleModal}
        close={CloseBtn}
        tag="div"
      >
        <h5 className="modal-title">Add New User </h5>
      </ModalHeader>
      <ModalBody className="flex-grow-1">
        <div className="mb-1">
          <Label className="form-label" htmlFor="full-name">
            Full Name
          </Label>
          <InputGroup>
            <InputGroupText>
              <User size={15} />
            </InputGroupText>
            <Input
              id="full-name"
              name="name"
              placeholder="Bruce Wayne"
              onChange={(e) => handleChange(e)}
            />
          </InputGroup>
        </div>
        <div className="mb-1">
          <Label className="form-label" htmlFor="post">
            Speciality
          </Label>
          <InputGroup>
            <InputGroupText>
              <Briefcase size={15} />
            </InputGroupText>
            <select
              name="speciality"
              id="post"
              onChange={changeType.bind(this)}
              className="form-control"
            >
              {types.map((item) => {
                return (
                  <option
                    name="type"
                    key={item.id}
                    value={item.id}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  >
                    {item.name}
                  </option>
                );
              })}
            </select>
          </InputGroup>
        </div>
        <div className="mb-1">
          <Label className="form-label" htmlFor="goal">
            Goal
          </Label>
          <InputGroup>
            <InputGroupText>
              <User size={15} />
            </InputGroupText>
            <Input
              id="goal"
              name="goal"
              type="number"
              placeholder="1000"
              onChange={(e) => handleChange(e)}
            />
          </InputGroup>
        </div>
        <div className="mb-1">
          <Label className="form-label" htmlFor="goal">
            Report Order
          </Label>
          <InputGroup>
            <InputGroupText>
              <User size={15} />
            </InputGroupText>
            <Input
              id="order"
              name="order"
              type="number"
              placeholder="0 - 10"
              onChange={(e) => handleChange(e)}
            />
          </InputGroup>
        </div>
        <div className="mb-2">
          <Label className="form-label" htmlFor="joining-date">
            Joining Date
          </Label>
          <InputGroup>
            <InputGroupText>
              <Calendar size={15} />
            </InputGroupText>
            <Flatpickr
              className="form-control"
              id="joining_date"
              value={picker}
              onChange={(date) => setPicker(date)}
            />
          </InputGroup>
        </div>
        {loading && loading === true ? (
          <div style={{ marginLeft: "800px" }}>
            <ClipLoader loading={loading} />
          </div>
        ) : (
          <>
            <Button className="me-1" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button color="secondary" onClick={handleModal} outline>
              Cancel
            </Button>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default AddNewModal;
