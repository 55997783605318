import React, { useRef, useState, useEffect } from "react";
import Header from "../../components/Header";
import { Button, Row, Col, Label } from "reactstrap";
import moment from "moment-timezone";
import axios from "axios";
import swal from "sweetalert";
import Flatpickr from "react-flatpickr";
import "../../assets/scss/report.css";
import ReactToPrint from "react-to-print";
import { ClipLoader } from "react-spinners";
import { OFFICE_REPORT, PROVIDER_REPORT } from "../../utilities/Endpoints";

const Report = React.forwardRef((props, ref) => {
  moment.tz.setDefault("America/Los_Angeles");

  const componentRef = useRef();
  const officeRef = useRef();

  const [data, setData] = useState([]);
  const [dates, setDates] = useState([]);
  const [collection, setCollection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingOffice, setLoadingOffice] = useState(false);
  const [picker, setPicker] = useState(new Date());

  //API CALLS HERE
  useEffect(() => {
    getReportData(moment(picker.toString()).format("YYYY-MM-DD"));
    getCollectionData(moment(picker.toString()).format("YYYY-MM-DD"));
  }, []);

  //PROVIDER REPORT API
  const getReportData = (date) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const body = {
      date: date,
    };

    axios
      .post(PROVIDER_REPORT + false, body, {
        headers: { user: token },
      })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        swal("Oops!", "Error Fetching Provider Report!", "error");
      });
  };

  //OFFICE REPORT API
  const getCollectionData = (date) => {
    setLoadingOffice(true);
    const token = localStorage.getItem("token");
    const body = {
      date: date,
    };

    axios
      .post(OFFICE_REPORT, body, {
        headers: { user: token },
      })
      .then((response) => {
        setCollection(response.data);
        setLoadingOffice(false);
      })
      .catch((err) => {
        setLoadingOffice(false);
        swal("Oops!", "Error Fetching Office Report!", "error");
      });
  };

  //WEEK DATES FUNCTION

  const week = moment(
    moment(picker.toString()).format("YYYY-MM-DD"),
    "YYYY-MM-DD",
  ).week();
  const year = moment(
    moment(picker.toString()).format("YYYY-MM-DD"),
    "YYYY-MM-DD",
  ).year();

  // const getWeekDaysByWeekNumber = (weeknumber, year) => {
  // 	var dateformat = 'YYYY-MM-DD';
  // 	var date = moment()
  // 			.isoWeek(weeknumber || 1)
  // 			.isoWeekYear(year)
  // 			.startOf('week')
  // 			.isoWeekday('Monday'),
  // 		weeklength = 5,
  // 		result = [];
  // 	while (weeklength--) {
  // 		result.push(date.format(dateformat));
  // 		date.add(1, 'day');
  // 	}
  // 	setDates(result);
  // 	return result;
  // };

  function getWeekDaysByWeekNumber(weekNumber, year) {
    var dateformat = "YYYY-MM-DD";
    const startOfWeek = moment().year(year).week(weekNumber).startOf("week");
    const endOfWeek = moment().year(year).week(weekNumber).endOf("week");

    const weekDates = [];
    let currentDate = startOfWeek;
    while (currentDate <= endOfWeek) {
      weekDates.push(moment(currentDate).format(dateformat));
      currentDate = moment(currentDate).add(1, "days");
    }
    setDates(weekDates.filter((date) => moment(date).isoWeekday() <= 5));
    return weekDates.filter((date) => moment(date).isoWeekday() <= 5);
  }

  //WEEK DATES CALCULATION
  useEffect(() => {
    getWeekDaysByWeekNumber(week, year);
  }, [week, year]);

  //function for comma sepertator
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div>
      <Header />
      <div className="main-content">
        <div className="header">
          <section className="section">
            <div className="section-header">
              <h4>Provider Report</h4>
            </div>
            <div className="section-body">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <Row>
                      <Col lg="8" md="8" sm="12" className="mb-1">
                        <Label className="form-label" htmlFor="nameMulti">
                          Date
                        </Label>
                        <Flatpickr
                          size="md"
                          className="form-control"
                          value={picker}
                          onChange={(date) => {
                            setPicker(date);
                            getReportData(
                              moment(date.toString()).format("YYYY-MM-DD"),
                            );
                            getCollectionData(
                              moment(date.toString()).format("YYYY-MM-DD"),
                            );
                          }}
                          id="default-picker"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" lg="6" sm="12"></Col>
                      <Col></Col>
                      <Col></Col>
                      <Col
                        md="6"
                        lg="6"
                        sm="12"
                        style={{ padding: "0", marginTop: "28px" }}
                      >
                        <ReactToPrint
                          trigger={() => (
                            <button className="btn btn-success">
                              Print Provider Report
                            </button>
                          )}
                          content={() => componentRef.current}
                        />
                      </Col>
                      <Col style={{ padding: "0", marginTop: "28px" }}>
                        <ReactToPrint
                          trigger={() => (
                            <Button color="success">Print Office Report</Button>
                          )}
                          content={() => officeRef.current}
                        />
                      </Col>
                    </Row>
                  </div>
                  {loading === true ? (
                    <div style={{ marginLeft: "800px" }}>
                      <ClipLoader loading={loading} />
                    </div>
                  ) : (
                    <div className="card-body" ref={componentRef}>
                      <br />
                      <div style={{ textAlign: "center" }}>
                        <h4>{localStorage.getItem("name")}</h4>
                      </div>
                      <div className="col-12 col-md-12 col-lg-12">
                        <table
                          className="table table-hover table-striped table-sm table-dates"
                          id="voucher-stage"
                        >
                          <thead>
                            <tr>
                              <th colSpan={1}>#</th>
                              <th className="date-th">Dated</th>
                              {dates &&
                                dates.map((date, index) => {
                                  return (
                                    <th key={index} scope="col">
                                      {date}
                                    </th>
                                  );
                                })}
                            </tr>
                          </thead>
                        </table>
                        {data &&
                          data.map((d, i) => {
                            return (
                              <>
                                <table
                                  className="table table-hover table-striped table-sm table-report"
                                  id="voucher-stage"
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <th className="title-table" rowSpan={0}>
                                        <caption>
                                          <strong>{d.name}</strong>
                                        </caption>
                                      </th>
                                      <th scope="row">Production</th>
                                      {d &&
                                        d.report.map((arr, k) => {
                                          return (
                                            <>
                                              <td>
                                                <b>
                                                  {" "}
                                                  {numberWithCommas(
                                                    arr.production,
                                                  )}
                                                </b>
                                              </td>
                                            </>
                                          );
                                        })}
                                    </tr>
                                    <tr>
                                      <th scope="row">Goal</th>
                                      {d &&
                                        d.report.map((arr, k) => {
                                          return (
                                            <>
                                              <td>
                                                <b>
                                                  {" "}
                                                  {numberWithCommas(arr.goal)}
                                                </b>
                                              </td>
                                            </>
                                          );
                                        })}
                                    </tr>
                                    <tr>
                                      <th scope="row">Surplus</th>
                                      {d &&
                                        d.report.map((arr, k) => {
                                          return (
                                            <>
                                              <td
                                                style={
                                                  arr.production - arr.goal >= 0
                                                    ? { color: "green" }
                                                    : { color: "red" }
                                                }
                                              >
                                                <b>
                                                  {" "}
                                                  {numberWithCommas(
                                                    arr.surplus,
                                                  )}
                                                </b>
                                              </td>
                                            </>
                                          );
                                        })}
                                    </tr>
                                    <tr>
                                      <th scope="row">MTD Production</th>
                                      {d &&
                                        d.report.map((arr, k) => {
                                          return (
                                            <>
                                              <td>
                                                <b>
                                                  {" "}
                                                  {numberWithCommas(
                                                    arr.mtd_production,
                                                  )}
                                                </b>
                                              </td>
                                            </>
                                          );
                                        })}
                                    </tr>
                                    <tr>
                                      <th scope="row">MTD Goal</th>
                                      {d &&
                                        d.report.map((arr, k) => {
                                          return (
                                            <>
                                              <td>
                                                <b>
                                                  {" "}
                                                  {numberWithCommas(
                                                    arr.mtd_goal,
                                                  )}
                                                </b>
                                              </td>
                                            </>
                                          );
                                        })}
                                    </tr>
                                    <tr>
                                      <th scope="row">MTD Surplus</th>
                                      {d &&
                                        d.report.map((arr, k) => {
                                          return (
                                            <>
                                              <td
                                                style={
                                                  arr.mtd_production -
                                                    arr.mtd_goal >=
                                                  0
                                                    ? { color: "green" }
                                                    : { color: "red" }
                                                }
                                              >
                                                <b>
                                                  {" "}
                                                  {numberWithCommas(
                                                    arr.mtd_surplus,
                                                  )}
                                                </b>
                                              </td>
                                            </>
                                          );
                                        })}
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className="section">
            <div className="section-header">
              <h4>Office Report</h4>
            </div>
            <div className="section-body">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <Row>
                      <Col style={{ padding: "0", float: "right" }}>
                        <ReactToPrint
                          trigger={() => (
                            <Button color="success">Print Office Report</Button>
                          )}
                          content={() => officeRef.current}
                        />
                      </Col>
                    </Row>
                  </div>
                  {loadingOffice === true ? (
                    <div style={{ marginLeft: "800px" }}>
                      <ClipLoader loading={loadingOffice} />
                    </div>
                  ) : (
                    <div className="card-body" ref={officeRef}>
                      <div className="col-12 col-md-12 col-lg-12">
                        <br />

                        <div>
                          <div style={{ textAlign: "center" }}>
                            <h4>{localStorage.getItem("name")}</h4>
                          </div>
                          <table
                            className="table table-hover table-striped table-sm table-dates"
                            id="voucher-stage"
                          >
                            <thead>
                              <tr>
                                <th colSpan={1}>#</th>
                                <th className="date-th">Dated</th>
                                {dates &&
                                  dates.map((date, index) => {
                                    return (
                                      <th key={index} scope="col">
                                        {date}
                                      </th>
                                    );
                                  })}
                              </tr>
                            </thead>
                          </table>
                          <table
                            className="table table-hover table-striped table-sm table-report"
                            id="voucher-stage"
                            style={{ width: "100%" }}
                          >
                            <tbody>
                              <tr>
                                <th className="title-table" rowSpan={0}>
                                  <caption>
                                    <strong>Total Collections</strong>
                                  </caption>
                                </th>
                                <th scope="row">Cheque</th>
                                {collection &&
                                  collection.map((arr, k) => {
                                    return (
                                      <>
                                        <td>
                                          <b> {numberWithCommas(arr.cheque)}</b>
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                              <tr>
                                <th scope="row">Cash</th>
                                {collection &&
                                  collection.map((arr, k) => {
                                    return (
                                      <>
                                        <td>
                                          <b> {numberWithCommas(arr.cash)}</b>
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                              <tr>
                                <th scope="row">Merchant</th>
                                {collection &&
                                  collection.map((arr, k) => {
                                    return (
                                      <>
                                        <td>
                                          <b>
                                            {" "}
                                            {numberWithCommas(arr.merchant)}
                                          </b>
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                              <tr>
                                <th scope="row">EFT</th>
                                {collection &&
                                  collection.map((arr, k) => {
                                    return (
                                      <>
                                        <td>
                                          <b> {numberWithCommas(arr.EFT)}</b>
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                              <tr>
                                <th scope="row">Total Collection</th>
                                {collection &&
                                  collection.map((arr, k) => {
                                    return (
                                      <>
                                        <td>
                                          <b>
                                            {" "}
                                            {numberWithCommas(
                                              arr.total_collection,
                                            )}
                                          </b>
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                              <tr>
                                <th>MTD Collection</th>
                                {collection &&
                                  collection.map((arr, k) => {
                                    return (
                                      <>
                                        <td>
                                          <b>
                                            {" "}
                                            {numberWithCommas(
                                              arr.mtd_collection,
                                            )}
                                          </b>
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                              <tr>
                                <th scope="row">MTD Practice Production</th>
                                {collection &&
                                  collection.map((arr, k) => {
                                    return (
                                      <>
                                        <td>
                                          <b>
                                            {" "}
                                            {numberWithCommas(
                                              arr.mtd_production,
                                            )}
                                          </b>
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                              <tr>
                                <th scope="row">MTD Practice Goal</th>
                                {collection &&
                                  collection.map((arr, k) => {
                                    return (
                                      <>
                                        <td>
                                          <b>
                                            {" "}
                                            {numberWithCommas(arr.mtd_goal)}
                                          </b>
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                            </tbody>
                          </table>
                          <table
                            className="table table-hover table-striped table-sm table-report"
                            id="voucher-stage"
                            style={{ width: "100%" }}
                          >
                            <tbody>
                              <tr>
                                <th className="title-table" rowSpan={0}>
                                  <caption>
                                    <strong>Total Patients</strong>
                                  </caption>
                                </th>
                                <th scope="row">New Patient Online</th>
                                {collection &&
                                  collection.map((arr, k) => {
                                    return (
                                      <>
                                        <td>
                                          <b>{arr.p_online}</b>
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                              <tr>
                                <th scope="row">New Patient Referral</th>
                                {collection &&
                                  collection.map((arr, k) => {
                                    return (
                                      <>
                                        <td>
                                          <b>{arr.p_referral}</b>
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                              <tr>
                                <th scope="row">New Patient other</th>
                                {collection &&
                                  collection.map((arr, k) => {
                                    return (
                                      <>
                                        <td>
                                          <b>{arr.p_other}</b>
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                              <tr>
                                <th scope="row">Patient Leave</th>
                                {collection &&
                                  collection.map((arr, k) => {
                                    return (
                                      <>
                                        <td>
                                          <b>{arr.p_leave}</b>
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                              <tr>
                                <th scope="row">Total New Patient</th>
                                {collection &&
                                  collection.map((arr, k) => {
                                    return (
                                      <>
                                        <td>
                                          <b>{arr.total_patients}</b>
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                              <tr>
                                <th scope="row">MTD New Patient</th>
                                {collection &&
                                  collection.map((arr, k) => {
                                    return (
                                      <>
                                        <td>
                                          <b>{arr.mtd_patient}</b>
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                              <tr>
                                <th scope="row">MTD Patient leave</th>
                                {collection &&
                                  collection.map((arr, k) => {
                                    return (
                                      <>
                                        <td>
                                          <b>{arr.mtd_patient_leave}</b>
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                            </tbody>
                          </table>
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
});

export default Report;
