import axios from "axios";
import "flatpickr/dist/themes/dark.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { ClipLoader } from "react-spinners";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import Header from "../../components/Header";
import Divider from "../../components/divider";
import { GET_USERS, POST_DAILY_REPORT } from "../../utilities/Endpoints";
// moment.tz.setDefault('America/Los_Angeles');
// moment.tz.setDefault('Asia/Asghabat');

const Forms = () => {
  const [picker, setPicker] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [state, setState] = useState({
    cheque: 0,
    cash: 0,
    merchant: 0,
    eft: 0,
    patientLeave: 0,
    pOnline: 0,
    pReferral: 0,
    pOther: 0,
    noShows: 0,
    reschedule: 0,
  });

  useEffect(() => {
    getUsers(moment(picker.toString()).format("YYYY-MM-DD"));
  }, []);

  // USERS GET API CALL WITH THE REPORT AND COLLECTION DATA
  const getUsers = (date) => {
    const token = localStorage.getItem("token");
    setLoading(true);
    let body = { trans_date: date };
    axios
      .post(GET_USERS, body, {
        headers: { user: token },
      })
      .then((response) => {
        setData(response.data.users);
        console.log(response.data.users);
        setLoading(false);
        setState({
          cheque:
            response.data.collections && response.data.collections.length > 0
              ? response.data.collections[0].c_cheque
              : 0,
          cash:
            response.data.collections && response.data.collections.length > 0
              ? response.data.collections[0].c_cash
              : 0,
          merchant:
            response.data.collections && response.data.collections.length > 0
              ? response.data.collections[0].c_merchant
              : 0,
          eft:
            response.data.collections && response.data.collections.length > 0
              ? response.data.collections[0].c_eft
              : 0,
          patientLeave:
            response.data.collections && response.data.collections.length > 0
              ? response.data.collections[0].p_leave
              : 0,
          pOnline:
            response.data.collections && response.data.collections.length > 0
              ? response.data.collections[0].p_online
              : 0,
          pReferral:
            response.data.collections && response.data.collections.length > 0
              ? response.data.collections[0].p_referral
              : 0,
          pOther:
            response.data.collections && response.data.collections.length > 0
              ? response.data.collections[0].p_other
              : 0,
          noShows:
            response.data.collections && response.data.collections.length > 0
              ? response.data.collections[0].no_shows
              : 0,
          reschedule:
            response.data.collections && response.data.collections.length > 0
              ? response.data.collections[0].no_reschedule
              : 0,
        });
      })
      .catch((err) => {
        setLoading(false);
        swal("Oops!", "Error Fetching Data!", "error");
      });
  };

  // DAILY REPORT API CALL
  const postDailyReport = async (reportData) => {
    try {
      // Show loading spinner while the request is being made
      setLoadingSubmit(true);

      const token = localStorage.getItem("token");

      // Make the POST request using axios
      await axios.post(POST_DAILY_REPORT, reportData, {
        headers: { user: token },
      });

      // Hide loading spinner after the request is completed
      setLoadingSubmit(false);

      // Update the currentDate state with the next day
      const newDate = moment(currentDate, "YYYY-MM-DD").add(1, "days");
      setCurrentDate(moment(newDate.toString()).format("YYYY-MM-DD"));

      // Show a success notification to the user
      swal({
        title: "Success",
        text: "Data entered Successfully",
        icon: "success",
        button: "OK",
      });

      // Get users data for the new date
      getUsers(newDate);
    } catch (err) {
      // Hide loading spinner in case of error
      setLoadingSubmit(false);

      // Show an error notification to the user
      swal("Oops!", "Something went wrong!", "error");
    }
  };

  // HANDLE CHANGE FUNC FOR COLLECTION FIELDS
  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // USER DATA ARRAY OF OBJECTS
    const newData = data.map((data) => {
      const obj = {
        trans_date: moment(currentDate.toString()).format("YYYY-MM-DD"),
        id: data.id,
        user_id: data.user_id,
        production: parseFloat(data.production) || 0,
        total_appointments: parseInt(data.appointments) || 0,
        projection: parseFloat(data.projection) || 0,
      };
      return obj;
    });

    // COLLECTION DATA OBJECT

    const collectionData = {
      trans_date: moment(currentDate.toString()).format("YYYY-MM-DD"),
      c_cheque: parseFloat(state.cheque) || 0,
      c_cash: parseFloat(state.cash) || 0,
      c_merchant: parseFloat(state.merchant) || 0,
      c_eft: parseFloat(state.eft) || 0,
      p_leave: parseInt(state.patientLeave) || 0,
      p_online: parseInt(state.pOnline) || 0,
      p_referral: parseInt(state.pReferral) || 0,
      p_other: parseInt(state.pOther) || 0,
      no_shows: parseInt(state.noShows) || 0,
      no_reschedule: parseInt(state.reschedule) || 0,
    };

    // PAYLOAD FOR API CALL
    const reportData = {
      report: newData,
      collection: collectionData,
    };

    postDailyReport(reportData);
  };

  const renderDoctorRows = () => {
    const rows = [];
    for (let i = 0; i < data.length; i++) {
      rows.push(
        <>
          <Col md="3" sm="12" className="mb-1">
            <Label className="form-label" htmlFor="lastNameMulti">
              Select Name
            </Label>
            <Input
              disabled
              type="text"
              name="name"
              id="lastNameMulti"
              value={data && data[i].name}
            />
          </Col>
          <Col md="3" sm="12" className="mb-1">
            <Label className="form-label">Production</Label>
            <Input
              type="number"
              name="production"
              id="lastNameMulti"
              value={data && data[i].production}
              onChange={(e) => {
                let temp = [...data];
                temp[i].production = e.target.value;
                setData(temp);
              }}
            />
          </Col>
          <Col md="3" sm="12" className="mb-1">
            <Label className="form-label" htmlFor="lastNameMulti">
              No. of Appointments
            </Label>
            <Input
              type="number"
              name="appointments"
              id="lastNameMulti"
              value={data && data[i].appointments}
              onChange={(e) => {
                let temp = [...data];
                temp[i].appointments = e.target.value;
                setData(temp);
              }}
            />
          </Col>
          <Col md="3" sm="12" className="mb-1">
            <Label className="form-label" htmlFor="lastNameMulti">
              Projection
            </Label>
            <Input
              type="number"
              name="projection"
              id="lastNameMulti"
              value={data && data[i].projection}
              onChange={(e) => {
                let temp = [...data];
                temp[i].projection = e.target.value;
                setData(temp);
              }}
            />
          </Col>
          {/* <Col sm='1'></Col> */}
        </>,
      );
    }
    return rows;
  };

  return (
    <>
      <Header />
      <div className="main-content">
        <Row>
          <Col md="12" sm="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Daily Practice Form</CardTitle>
              </CardHeader>
              <CardBody>
                <Form id="reportForm">
                  <Row>
                    <Row>
                      <Col md="3" sm="12" className="mb-1">
                        <Label className="form-label" htmlFor="nameMulti">
                          Date
                        </Label>
                        <Flatpickr
                          className="form-control"
                          value={currentDate}
                          onChange={(date) => {
                            setPicker(date);
                            setCurrentDate(
                              moment(date.toString()).format("YYYY-MM-DD"),
                            );
                            getUsers(
                              moment(date.toString()).format("YYYY-MM-DD"),
                            );
                          }}
                          id="default-picker"
                        />
                      </Col>
                    </Row>
                    {data && renderDoctorRows()}
                    <Col sm="12">
                      <div style={{ marginLeft: "800px" }}>
                        <ClipLoader loading={loading} />
                      </div>
                      <Divider />
                    </Col>
                    <Col md="3" sm="12" className="mb-1">
                      <Label className="form-label" htmlFor="cityMulti">
                        Check Collection
                      </Label>
                      <Input
                        type="number"
                        name="cheque"
                        step="0.00"
                        value={state && state.cheque}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </Col>
                    <Col md="3" sm="12" className="mb-1">
                      <Label className="form-label" htmlFor="cityMulti">
                        Cash Collection
                      </Label>
                      <Input
                        type="number"
                        name="cash"
                        step="0.00"
                        value={state && state.cash}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </Col>
                    <Col md="3" sm="12" className="mb-1">
                      <Label className="form-label" htmlFor="CountryMulti">
                        Merchant Collection
                      </Label>
                      <Input
                        type="number"
                        name="merchant"
                        step="0.00"
                        value={state && state.merchant}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </Col>
                    <Col md="3" sm="12" className="mb-1">
                      <Label className="form-label" htmlFor="CompanyMulti">
                        EFT Collection
                      </Label>
                      <Input
                        type="number"
                        name="eft"
                        value={state && state.eft}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </Col>
                    <Col md="3" sm="12" className="mb-1">
                      <Label className="form-label" htmlFor="EmailMulti">
                        New Patient - Online
                      </Label>
                      <Input
                        type="number"
                        name="pOnline"
                        value={state && state.pOnline}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col md="3" sm="12" className="mb-1">
                      <Label className="form-label" htmlFor="EmailMulti">
                        New Patient - Referrral
                      </Label>
                      <Input
                        type="number"
                        name="pReferral"
                        value={state && state.pReferral}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col md="3" sm="12" className="mb-1">
                      <Label className="form-label" htmlFor="EmailMulti">
                        New Patient - Other
                      </Label>
                      <Input
                        type="number"
                        name="pOther"
                        value={state && state.pOther}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col md="3" sm="12" className="mb-1">
                      <Label className="form-label" htmlFor="EmailMulti">
                        Patient Leave
                      </Label>
                      <Input
                        type="number"
                        name="patientLeave"
                        value={state && state.patientLeave}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col md="3" sm="12" className="mb-1">
                      <Label className="form-label" htmlFor="EmailMulti">
                        No. of Reschedule
                      </Label>
                      <Input
                        type="number"
                        name="reschedule"
                        value={state && state.reschedule}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col md="3" sm="12" className="mb-1">
                      <Label className="form-label" htmlFor="EmailMulti">
                        No. of No shows
                      </Label>
                      <Input
                        type="number"
                        name="noShows"
                        value={state && state.noShows}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col sm="12">
                      {loadingSubmit && loadingSubmit === true ? (
                        <div style={{ marginLeft: "800px" }}>
                          <ClipLoader loading={loadingSubmit} />
                        </div>
                      ) : (
                        <div className="d-flex mt-1">
                          <Button
                            className="me-1"
                            color="primary"
                            type="submit"
                            onClick={onSubmit}
                          >
                            Submit
                          </Button>
                          <Button
                            outline
                            color="secondary"
                            type="reset"
                            onClick={() => {}}
                          >
                            Reset
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Forms;
