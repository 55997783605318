import { Redirect, Route } from "react-router-dom";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  let isAuth = false;

  const validateToken = (token) => {
    try {
      return true;
    } catch (err) {
      return false;
    }
  };

  const token = localStorage.getItem("token");
  if (token !== undefined && token !== "") {
    const isOk = validateToken(token);
    if (isOk) {
      isAuth = token;
    }
  }

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuth ? <Component {...props} /> : <Redirect to="/auth" />
      }
    />
  );
}

export default ProtectedRoute;
