import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import swal from "sweetalert";
import moment from "moment-timezone";
import Header from "../../../components/Header";
import AddNewModal from "./addNewModal";
import ConfirmationAlert from "./confirmationAlert";
import EditModal from "./editModal";
import { GET_ONLY_USERS, DELETE_USERS } from "../../../utilities/Endpoints";
moment.tz.setDefault("America/Los_Angeles");

const Employee = () => {
  const token = localStorage.getItem("token");
  const [isOpen, setIsOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [editedUser, setEditedUser] = useState(null);
  const [userID, setUserID] = useState(null);
  const [status, setStatus] = useState(true);
  const [activeUser, setActiveUser] = useState(true);

  const deleteUser = (id, status) => {
    axios
      .delete(DELETE_USERS + id + "/" + status, {
        headers: { user: token },
      })
      .then((response) => {
        swal({
          title: "Success",
          text: "User Status Updated Successfully",
          icon: "success",
          button: "OK",
        });
        setUsers(response.data);
      })
      .catch((err) => {
        swal("Oops!", "Something went wrong!", "error");
      });
  };

  const getData = (active) => {
    axios
      .get(GET_ONLY_USERS + "?status=" + active, {
        headers: { user: token },
      })
      .then((response) => {
        setLoading(false);
        setUsers(response.data);
        console.log(response.data);
      })
      .catch(() => {
        setLoading(false);
        swal("Oops!", "Error Fetching Users!", "error");
      });
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  const handleModal = (e, user) => {
    setEditedUser(user);
    setEditModal(!editModal);
  };

  const handleDelete = (e, id, status) => {
    e.preventDefault();
    deleteUser(id, status);
    setAlert(!alert);
  };

  const handleCloseAlert = () => {
    setAlert(!alert);
  };

  useEffect(() => {
    getData(activeUser);
  }, []);

  return (
    <div>
      <Header />
      <div className="main-content">
        <AddNewModal
          open={isOpen}
          handleModal={handleClose}
          getUsers={getData}
        />

        {editedUser && (
          <EditModal
            isOpen={editModal}
            handleModal={handleModal}
            user={editedUser}
            getUsers={getData}
            activeToggle={activeUser}
          />
        )}

        <ConfirmationAlert
          isOpen={alert}
          deleteAction={handleDelete}
          userID={userID}
          status={status}
          closeAlert={handleCloseAlert}
        />

        <div className="mb-1" style={{ float: "left" }}>
          <Button
            className=""
            color="primary"
            type="submit"
            onClick={() => setIsOpen(!isOpen)}
          >
            Add New
          </Button>
        </div>
        <h5 style={{ float: "right", marginBottom: "0px", marginTop: "4px" }}>
          {activeUser && activeUser === true
            ? "Active Users"
            : "Inactive Users"}
        </h5>
        <div className="mb-1" style={{ float: "right", marginRight: "20px" }}>
          <label className="switch">
            <input
              checked={activeUser}
              type="checkbox"
              aria-label="Toggle Status"
              onChange={(e) => {
                setActiveUser(!activeUser);
                getData(!activeUser);
              }}
            />
            <span className="slider"></span>
          </label>
        </div>

        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">id</th>
              <th scope="col">Name</th>
              <th scope="col">Speciality</th>
              <th scope="col">Goal</th>
              <th scope="col">Joining Date</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading && loading === true ? (
              <div style={{ marginLeft: "800px" }}>
                <ClipLoader loading={loading} />
              </div>
            ) : null}
            {users &&
              users.map((user, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{user && user.name}</td>
                    <td>{user && user.type.name}</td>
                    <td>{user && user.goal}</td>
                    <td>
                      {moment(user && user.joining_date.toString()).format(
                        "DD-MM-YYYY",
                      )}
                    </td>
                    <td>
                      <Button
                        color="success"
                        type="submit"
                        onClick={(e) => handleModal(e, user)}
                      >
                        <span className="fa fa-edit"></span> Edit
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Employee;
